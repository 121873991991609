<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Albums
                        <v-spacer></v-spacer>
                        <!--<v-btn outlined @click="form.dialog = true" ma-0 small>New Album</v-btn>-->
                        <add-button @action="form.dialog = true, $refs.form ? $refs.form.reset(): ''"
                                    permission="album-create">
                            New Album
                        </add-button>
                    </v-card-title>


                    <v-layout pl-3 pr-3 wrap>
                        <v-flex xs3  v-for="(item,key) in form.items.data" :key="key">
                            <v-card class="mx-auto" max-width="400">
                                <v-img
                                        class="white--text align-end"
                                        height="200px"
                                        :src="item.image">
                                    <!--<v-card-title>Top 10 Australian beaches</v-card-title>-->
                                </v-img>

                                <v-card-subtitle class="pb-0">{{item.title.toUpperCase()}}</v-card-subtitle>

                                <v-card-text class="text--primary">

                                    <div>{{item.description}}</div>
                                </v-card-text>

                                <v-card-actions>
                                    <!--<v-btn-->
                                            <!--color="orange"-->
                                            <!--text>-->
                                        <!--Share-->
                                    <!--</v-btn>-->
                                    <view-button permission="image-read" @click.native="addImages(item.id)" :outlined="false"></view-button>

                                    <edit-button permission="album-update" @agree="form.edit(item)" :outlined="false"></edit-button>
                                    <delete-button permission="album-delete" @agree="deleteItem(item.id)" :outlined="false"></delete-button>
                                </v-card-actions>
                            </v-card>
                        </v-flex>
                    </v-layout>


                    <!--<v-layout style="padding: 0 15px;" wrap v-if="form.items.data.length">-->
                    <!--<v-flex v-for="(item,key) in form.items.data" :key="key" xs3 d-flex>-->
                    <!--<v-hover v-slot:default="{ hover }">-->
                    <!--<v-card flat tile class="d-flex">-->

                    <!--<div style="position:absolute;z-index: 99;right: 10px; bottom:10px">-->
                    <!--<view-button permission="image-read" @click.native="addImages(item.id)" :outlined="false"/>-->
                    <!--<edit-button permission="album-update" @agree="form.edit(item)" :outlined="false"/>-->
                    <!--<delete-button permission="album-delete" @agree="form.delete(item.id)" :outlined="false"/>-->
                    <!--</div>-->


                    <!--<v-img :src="item.image" :lazy-src="item.image" aspect-ratio="1"-->
                    <!--class="grey lighten-2">-->
                    <!--<v-expand-transition>-->
                    <!--<div v-if="hover"-->
                    <!--class="d-flex transition-fast-in-fast-out blue darken-2 v-card&#45;&#45;reveal display-3 white&#45;&#45;text"-->
                    <!--style="height: 100%;">-->
                    <!--<div class="album_style_mis">-->
                    <!--<h3>{{item.title.toUpperCase()}}</h3>-->
                    <!--<p>{{item.description}}</p>-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--</v-expand-transition>-->
                    <!--<template v-slot:placeholder>-->
                    <!--<v-layout fill-height align-center justify-center ma-0>-->
                    <!--<v-progress-circular indeterminate-->
                    <!--color="grey lighten-5"></v-progress-circular>-->
                    <!--</v-layout>-->
                    <!--</template>-->
                    <!--</v-img>-->

                    <!--</v-card>-->
                    <!--</v-hover>-->
                    <!--</v-flex>-->
                    <!--</v-layout>-->


                    <v-card-actions>
                        <v-spacer/>

                        <v-pagination v-if="form.items.data.length > 0" v-model="pagination.page"
                                      :length="form.items.meta.last_page"></v-pagination>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-flex>

            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Album Details</span>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-form ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                v-model="valid"
                                :lazy-validation="lazy">
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs12 class="mb-0">
                                        <v-text-field label="Album Title*" autocomplete="off" outlined dense required
                                                      class="pa-0"
                                                      v-model="form.title" name="name"
                                                      :error-messages="form.errors.get('title')"
                                                      :rules="[v => !!v || 'Album Title Field is required']"
                                        />
                                    </v-flex>
                                    <v-flex xs12 class="ma-0 pa-0">
                                        <v-textarea outlined dense :error-messages="form.errors.get('description')"
                                                    v-model="form.description" name="description" label="Description"
                                                    hint="Short and sweet notice is always eye catching."
                                        />
                                    </v-flex>
                                    <v-flex xs12 text-xs-center class="ma-0 pa-0">
                                        <v-scroll-y-transition>
                                            <v-btn outlined @click="onPickFile" v-if="!imagePicker.url">
                                                <v-icon left dark small>add_a_photo</v-icon>
                                                Select Album Cover
                                            </v-btn>
                                        </v-scroll-y-transition>
                                        <input type="file" style="display: none" ref="image" accept="image/*"
                                               @change="onFilePicked">
                                    </v-flex>
                                    <v-scroll-y-reverse-transition>
                                        <v-flex xs12 text-xs-center class="ma-0 pa-0" v-if="imagePicker.url"
                                                style="position: relative;">
                                            <v-btn flat outlined icon color="green" @click="onPickFile">
                                                <v-icon small>compare_arrows</v-icon>
                                            </v-btn>
                                            <v-avatar :size="112" color="grey lighten-4">
                                                <img :src="imagePicker.url" alt="avatar">
                                            </v-avatar>
                                            <v-btn flat outlined icon color="red" @click="resetImagePicker">
                                                <v-icon small>close</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-scroll-y-reverse-transition>
                                    <v-flex xs12>
                                        <small>*indicates required field</small>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import ImageCompressor from 'image-compressor.js'

    export default {
        data: () => ({
            valid: true,
            lazy: false,
            form: new Form({
                title: '',
                description: '',
                image: ''
            }, '/api/album'),
            search: null,
            pagination: {
                rowsPerPage: 10,
                page: 1,
                descending: true
            },
            imagePicker: {
                name: null,
                image: null,
                url: null
            }
        }),

        computed: {
            ...mapState(['batch'])
        },

        watch: {
            'pagination.page': function () {
                // console.log('testing...');
                this.get();
            }
        },
        mounted() {
            this.get();
        },

        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.store()
                }
            },
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&');
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.page = data.meta.current_page;
                    console.log(data.meta);
                })
            },

            store() {
                this.form.image = this.imagePicker.image;
                this.form.store().then(() => {
                    this.resetImagePicker();
                    this.get();
                });
            },
            onFilePicked(e) {
                const files = e.target.files;
                let fileName = files[0].name;
                if (fileName.lastIndexOf('.') <= 0) {
                    this.$store.dispatch('notifyError', "Please upload a valid image file!");
                    return
                }
                const fileReader = new FileReader();
                fileReader.readAsDataURL(files[0]);
                fileReader.addEventListener('load', () => {
                    this.imagePicker.url = fileReader.result;
                    this.imagePicker.name = fileName

                    const imageCompressor = new ImageCompressor();
                    imageCompressor.compress(files[0], {quality: .5})
                        .then((result) => {
                            this.imagePicker.image = result
                        })
                        .catch((err) => {
                            // Handle the error
                        })
                })
            },
            resetImagePicker() {
                this.imagePicker = {
                    url: undefined,
                    image: undefined,
                    name: undefined
                };
                this.$refs.image.value = "";
            },


            /**
             * Image Upload Handler
             */
            onPickFile() {
                this.$refs.image.click()
            },
            addImages(id) {
                this.$router.push({
                    name: 'album-image',
                    params: {
                        albumId: id
                    }
                })
            },
            deleteItem(item){
                this.form.delete(item)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .5;
        position: absolute;
        width: 100%;
    }

    /*.theme--light.v-text-field--outline > .v-input__control > .v-input__slot {*/
    /*border: 1px solid rgba(0, 0, 0, .54) !important;*/
    /*}*/

    .album_style_mis {
        padding: 0 20px;
        width: 50% !important;

        h3 {
            font-size: 17px !important;
            border-bottom: 1px solid white;
        }

        p {
            font-size: 15px;
        }
    }

    .container.grid-list-md .layout .flex {
        margin-bottom: 40px;
    }

    .v-btn--bottom.v-btn--absolute.v-btn--small.edit {
        right: 60px;
    }
</style>